import { BrowserRouter as Router} from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "./Banner";
import Navbar from "../Navbar";
import ToggleButton from "../ToggleButton";
import ProdukBanner from "./ProdukBan";     
import BenefitBelanja from "./BenefitBelanja";
import Contact from "./Contact";
import Footer from "./Footer";
import TenkaPage from "../About/TenkaPage";
import { Slide } from "react-slideshow-image";
import Loading from "../Loading";
import ImageSl from "../ImageSl";
import Tatkelpage from "../governance/governancepage"
import Footerr from "../Footerr"
import Newspage from "../News/NewsPage";
import HubinPage from "../Investor/Hubin";
import React, { useState, useEffect } from 'react';
import Bl from "../Footera";



const MainContent = () =>{
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const startLoading = () => {
      setIsLoading(true);
    };

    const stopLoading = () => {
      setIsLoading(false);
    };

	useEffect(() => {
      startLoading(); // Memulai animasi loading

      setTimeout(() => {
        stopLoading(); // Menghentikan animasi loading setelah konten selesai dimuat (contoh, gunakan yang sesuai)
      }, 3000);
    }, []);
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setContentLoaded(true);
      }, 2000);

      return () => {
        setContentLoaded(false);
      };
    }, []);

    const navigate = useNavigate();

    

    if(location.pathname == "/"){
      navigate("/home");
    }

    

    else if (location.pathname.startsWith("/home")) {
        return(
            <div>
            
     

  
      
                <Navbar/>
                <Banner />
                <ProdukBanner />
                <BenefitBelanja />
                <Contact />
                <Bl />
              
          
           
            </div>
        );

      } 
     
      else if(location.pathname.startsWith("/tenka"))
      {
        return(
            <div>
              {/* <Navbar /> */}
              <TenkaPage />
            </div>
        );
        
      }
      else if(location.pathname.startsWith("/tatkel"))
      {
        return(
            <div>
              {/* <Navbar /> */}
              <Tatkelpage/>
            </div>
        );

    } else if(location.pathname.startsWith("/hubin"))
      {
        return(
            <div>
              {/* <Navbar /> */}
              <HubinPage/>
            </div>
        );

    }  else if(location.pathname.startsWith("/berita")) {
      return(
        <div>
          <Newspage />
        </div>
      );
      
    } else{
        return(
            <div>
                <ImageSl/>
            </div>
        );
    }
}

function Home(){
    return(
        <Router>
            <MainContent />
            <ToggleButton />
        </Router>
    );
}
export default Home;