import logo from "../../assets/images/eci-remove.png";
import content from "../../assets/images/Produkban-remove.png"
import ps from "../../assets/logo/playstore.png"
import ap from "../../assets/logo/appstore.png"
import { useLanguage } from "../../context/LanguageContext";

function ProdukBanner() {
  const { selectedLanguage } = useLanguage();

const CustomKonten = () => {
    return(
      <div className="container">
        <div className="row text-left">
          <div className="col-md-6">
            <div>
              <img src={logo} alt="eci.id" className="logo-pd"/>
            </div>
            <div className="content_pd ">
              {selectedLanguage === 'id' ?
                'Nikmati kemudahan belanja di semua toko Electronic City hanya dalam 1 genggaman. Download aplikasinya di Playstore dan Apps Store, nikmati berbagai promo menarik dan cicilan 0% tanpa biaya tambahan. Tukarkan pula poin E-Cityzen dengan beragam hadiah menarik. Ayo gabung sekarang sebagai keluarga besar Electronic City dengan mendowload aplikasi eci.id.'
                : 'Enjoy the convenience of shopping at all Electronic City stores in just 1 hand. Download the application on Play Store and Apps Store, enjoy various attractive promos and 0% instalments at no additional cost. Also exchange E-Cityzen points for various attractive prizes. Come and join us and become Electronic City big family by downloading the eci.id application.'
              }
            
            </div>
            <div className="btn-pd">
              <a href="https://eci.id/">
              <button type="button" className="btn-primary">
                  <h4>{selectedLanguage === 'id' ?  'Gabung Sekarang': 'Join Now'}</h4>
              </button>
              </a>
            </div>
            <div className="or">
              <p>{selectedLanguage === 'id' ?  'Atau': 'Or'}</p>
            </div>
          
            <div class="store">
              <div className="col">
              <a href="https://play.google.com/store/search?q=eci.id+by+electronic+city&c=apps&hl=id-ID" >
                <img src={ps} alt="playstore" height={50}/>
              </a>
              </div>
              <div className="col">
                <a href="https://apps.apple.com/id/app/eci-id-by-electronic-city/id1456220356">
                 <img src={ap} alt="appstore" height={50} />
                </a>
              </div>
          
            </div>
          </div>
          <div className="col-md-2">
            <img width="600" src={content} alt="eci.id" className="img-pd"/>
          </div>
        </div>
        </div>
    )
}

  return (
    <div className="pd">
      <div className="dekstop">
        <CustomKonten />
      </div>
      <div className="container hp">
        <div className="">
            <div className="col-md">
                <div>
                <img src={logo} alt="eci.id" className="logo-pd"/>
                </div>
                <div className="col-md">
                    <img width="500" src={content} alt="eci.id" className="img-pd"/>
                </div>
                <div className="content_pd">

                <p>{selectedLanguage === 'id' ?
                'Nikmati kemudahan belanja di semua toko Electronic City hanya dalam 1 genggaman. Download aplikasinya di Playstore dan Apps Store, nikmati berbagai promo menarik dan cicilan 0% tanpa biaya tambahan. Tukarkan pula poin E-Cityzen dengan beragam hadiah menarik. Ayo gabung sekarang sebagai keluarga besar Electronic City dengan mendowload aplikasi eci.id.'
                : 'Enjoy the convenience of shopping at all Electronic City stores in just 1 hand. Download the application on Play Store and Apps Store, enjoy various attractive promos and 0% instalments at no additional cost. Also exchange E-Cityzen points for various attractive prizes. Come and join us and become Electronic City big family by downloading the eci.id application.'
              }</p>
                </div>
                <div className="col-md-8 btn-pd">
                  <a href="https://eci.id/">
                <button type="button" className="btn-primary">
                    <h4>{selectedLanguage === 'id' ?  'Gabung Sekarang': 'Join Now'}</h4>
                </button>
                </a>
                </div>
                <div className="or">
              <p>Atau</p>
            </div>
                <div class="store">
              <div className="col">
              <a href="https://play.google.com/store/search?q=eci.id+by+electronic+city&c=apps&hl=id-ID" >
                <img className="img-store" src={ps} alt="playstore" height={60}/>
              </a>
              </div>
              <div className="col">
                <a href="https://apps.apple.com/id/app/eci-id-by-electronic-city/id1456220356">
                 <img className="img-store" src={ap} alt="appstore" height={60} />
                </a>
              </div>
              </div>  
            </div>
            </div>
        </div>
    </div>
  );
}

export default ProdukBanner;
