import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import Navbar from "../Navbar";
// import "../Navbar.css";
import Footer from '../Footer';
import '../About/Tenkapage.css'
import pdf from "../../assets/logo/pdf-file.png"
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { BASE_API_URL } from '../../config/constants';

const TenkaPage = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSideMenuHidden, setIsSideMenuHidden] = useState(false);
  const [postData, setPostData] = useState([]);
  const [postDta, setPostDta] = useState([]);
  const [postData1, setPostData1] = useState([]);

  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    setIsSideMenuHidden(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsSideMenuHidden(!isMobileMenuOpen); // Tambahkan perubahan ini
  };
  
  const location = useLocation();
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        if(location.pathname === "/tatkel"){
          const apiUrl = BASE_API_URL+'/posts/list/menu_category?category=*F2B30D366CFF7C4585A87B431F101272244C42C4&page=1&limit=2&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          return
        }
        else if(location.pathname === "/tatkel/audit"){
          const imgD = BASE_API_URL+'/posts/list/category?group=user%20KAudit&page=1&limit=10&order=asc ';
          const response = await axios.get(imgD);
          const docUrll = BASE_API_URL+'/posts/list/category?group=komite%20audit&page=1&limit=13&order=asc ';
          const responss = await axios.get(docUrll);
          setPostData1(responss.data.posts);
          const docUrl = BASE_API_URL+'/posts/list/category?group=piagam%20ka&page=1&limit=13&order=desc ';
          const respons = await axios.get(docUrl);
          setPostData(response.data.posts);
          setPostDta(respons.data.posts);
          return
        }
        else if(location.pathname.startsWith ("/tatkel/audit/")){
          const hash = location.pathname.replace("/tatkel/audit/cv/", "");
          const imgD = BASE_API_URL+'/posts/list/category?group=user%20KAudit&page=1&limit=10&order=asc ';
          const response = await axios.get(imgD);      
          setPostData(response.data.posts);
          if (hash) {
            const apiUrl = BASE_API_URL+`/posts/detail/post/${hash}`;
            const respons = await axios.get(apiUrl);
            setPostDta(respons.data.post);
          }
          return
        }
        else if(location.pathname === "/tatkel/sekre"){
          const apiUrl = BASE_API_URL+'/posts/list/category?group=user%20sperusahaan&page=1&limit=13&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          const docUrll = BASE_API_URL+'/posts/list/category?group=sekretaris%20perusahaan&page=1&limit=13&order=asc';
          const responss = await axios.get(docUrll);
          setPostData1(responss.data.posts);
    
          return
        }
        else if(location.pathname.startsWith ("/tatkel/sekre/")){
          const hash = location.pathname.replace("/tatkel/sekre/cv/", "");
          const apiUrl = BASE_API_URL+'/posts/list/category?group=user%20sperusahaan&page=1&limit=13&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          if (hash) {
            const apiUrl = BASE_API_URL+`/posts/detail/post/${hash}`;
            const respons = await axios.get(apiUrl);
            setPostDta(respons.data.post);
          }
          return
        }
        else if(location.pathname === "/tatkel/internal"){
          const apiUrl = BASE_API_URL+'/posts/list/category?group=user%20iaudit&page=1&limit=13&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          const docUrll = BASE_API_URL+'/posts/list/category?group=internal%20audit&page=1&limit=13&order=asc';
          const responss = await axios.get(docUrll);
          setPostData1(responss.data.posts);
          const apiUrl1 = BASE_API_URL+'/posts/list/category?group=piagam%20ia&page=1&limit=13&order=desc';
          const response1 = await axios.get(apiUrl1);
          setPostDta(response1.data.posts);
          return
      } 
      else if(location.pathname.startsWith ("/tatkel/internal/")){
        const hash = location.pathname.replace("/tatkel/internal/cv/", "");
        const apiUrl = BASE_API_URL+'/posts/list/category?group=user%20iaudit&page=1&limit=13&order=desc';
        const response = await axios.get(apiUrl);
        setPostData(response.data.posts);
        if (hash) {
          const apiUrl = BASE_API_URL+`/posts/detail/post/${hash}`;
          const respons = await axios.get(apiUrl);
          setPostDta(respons.data.post);
        }
        return
    } 
    }
      catch (error) {
        console.error('Error fetching data:', error);
        setPostData({});
        // setIsLoading(false); // Set isLoading ke false jika terjadi error
      }
    };
    fetchPostData();
  }, [location.pathname]);

  const SideMenu = () => {
    const location = useLocation();
    
    if (location.pathname === "/tatkel") {
      return (
        <>
          <p><NavLink to='/tatkel' className="side_active">{selectedLanguage === 'en' ? 'BOD & BOC Awards ' : 'Piagam BOD & BOC'}</NavLink></p>
          <p><NavLink to='/tatkel/audit'>{selectedLanguage === 'en' ? 'Audit Committee' : 'Komite Audit'}</NavLink></p>
          <p><NavLink to='/tatkel/sekre'>{selectedLanguage === 'en' ? 'Corporate Secretary' : 'Sekretaris Perusahaan'}</NavLink></p>
          <p><NavLink to='/tatkel/internal'>{selectedLanguage === 'en' ? 'Internal Audit' : 'Internal Audit'}</NavLink></p>
        </>
      );
    } else if(location.pathname.startsWith ("/tatkel/audit")){
      return (
        <>
          <p><NavLink to='/tatkel'>{selectedLanguage === 'en' ? 'BOD & BOC Awards ' : 'Piagam BOD & BOC'}</NavLink></p>
          <p><NavLink to='/tatkel/audit' className="side_active">{selectedLanguage === 'en' ? 'Audit Committee' : 'Komite Audit'}</NavLink></p>
          <p><NavLink to='/tatkel/sekre'>{selectedLanguage === 'en' ? 'Corporate Secretary' : 'Sekretaris Perusahaan'}</NavLink></p>
          <p><NavLink to='/tatkel/internal'>{selectedLanguage === 'en' ? 'Internal Audit' : 'Internal Audit'}</NavLink></p>
        </>
      );
    } else if(location.pathname.startsWith ("/tatkel/sekre")){
      return (
        <>
          <p><NavLink to='/tatkel'>{selectedLanguage === 'en' ? 'BOD & BOC Awards ' : 'Piagam BOD & BOC'}</NavLink></p>
          <p><NavLink to='/tatkel/audit'>{selectedLanguage === 'en' ? 'Audit Committee' : 'Komite Audit'}</NavLink></p>
          <p><NavLink to='/tatkel/sekre' className="side_active">{selectedLanguage === 'en' ? 'Corporate Secretary' : 'Sekretaris Perusahaan'}</NavLink></p>
          <p><NavLink to='/tatkel/internal'>{selectedLanguage === 'en' ? 'Internal Audit' : 'Internal Audit'}</NavLink></p>
        </>
      );
    } else if(location.pathname.startsWith ("/tatkel/internal")){
      return (
        <>
          <p><NavLink to='/tatkel'>{selectedLanguage === 'en' ? 'BOD & BOC Awards ' : 'Piagam BOD & BOC'}</NavLink></p>
          <p><NavLink to='/tatkel/audit'>{selectedLanguage === 'en' ? 'Audit Committee' : 'Komite Audit'}</NavLink></p>
          <p><NavLink to='/tatkel/sekre'>{selectedLanguage === 'en' ? 'Corporate Secretary' : 'Sekretaris Perusahaan'}</NavLink></p>
          <p><NavLink to='/tatkel/internal' className="side_active">{selectedLanguage === 'en' ? 'Internal Audit' : 'Internal Audit'}</NavLink></p>
        </>
      );
  }
}


  const CustomKonten = () => {
    const location = useLocation();

    const handleDownload = (fileUrl) => {
      window.open(fileUrl, '_blank');
    };
    

    if (location.pathname === "/tatkel") {
      const postArray = Array.isArray(postData) ? postData : [];
      return (
        <>
          {postArray.map((post, index) => (
              <div key={index}>
                <h3 className="tt">{selectedLanguage === 'id' ?  post.Title : 'BOD & BOC Awards'}</h3>
                <div className='content_piagam'>
                <div>
                <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                </div>
                <div className='isi_piagam'>
                  <p style={{cursor: "pointer"}} onClick={() => handleDownload(post.File_upload)}>{post.Content_tx}</p>
                </div>
              </div>
              <br />
              </div>
            ))}
        </>
      );
    } else if (location.pathname === "/tatkel/audit") {
      const postArray = Array.isArray(postData) ? postData : [];
      const postArray1 = Array.isArray(postDta) ? postDta : [];
      const postArray2 = Array.isArray(postData1) ? postData1 : [];

      return (
        <>
            <div>
              <h3>{selectedLanguage === 'id' ?  'Komite Audit' : 'Audit Committee'}</h3>
              <div className='img_dewan'>
                {postArray.map((post, index) => (
                  <div key={index}>
                    <a style={{textDecoration :  "none", color: "black"}} href={`/tatkel/audit/cv/${post.Hash}`}>
                      <img src={post.Img_path} className='content_SO'></img>
                      <br/>
                      <br/>
                      <div className='name_dewan'>
                      <b><p className='name_komite'>{post.Title}</p></b>
                      <p>{
                        post.Title === 'Vivi Henny' ? 
                          (selectedLanguage === 'id' ? 'Ketua' : 'Head') :
                          (selectedLanguage === 'id' ? 'Anggota' : 'Member')
                      }</p>
                      </div>
                    </a>
                  </div>
                ))}
                  </div>

                  <br/>
                  <br/>

                  <div>
                  {postArray2.map((post, index) => (
                  <div key={index} >
                <div className='content_lpp'>
                <div className='isi_lpp'>
                  <p  dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? post.Content_tx : post.Content_tx_en }}></p>
                </div>
                </div>
                  </div>
                  ))}
                  </div>

                  <br/>
                  
                  <h3 className="tt">Piagam</h3>
                  <br />
                  {postArray1.map((post, index) => (
                    <div key={index}>
                <div className='content_piagam'>
                 <div> 
                   <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                 </div>
                 <div className='isi_piagam'>
                   <p style={{cursor: "pointer"}} onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                 </div>
              </div>
            </div>
                  ))}
            </div>
        </>
      );
    } else if(location.pathname.startsWith('/tatkel/audit/')){
      const hash = location.pathname.replace('/tatkel/audit/cv/','')
      const postArray = Array.isArray(postData) ? postData : [];
      const selectedPost = postArray.find(postDta => postDta.Hash === hash)
      if(selectedPost){
        return(
          <div className='detail_dewan'>
          <a  href="/tatkel/audit">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3 className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p  className='title_dewan' dangerouslySetInnerHTML={{ 
              __html: `
                ${
                  selectedPost.Title === 'Vivi Henny' ? 
                    (selectedLanguage === 'id' ? 'Ketua' : 'Head') :
                    (selectedLanguage === 'id' ? 'Anggota' : 'Member')
                }
              `
            }}></p>
            <p dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
        );
      }
      else if(hash){
        const hash = location.pathname.replace('/tenka/managemen/komisaris/cv/','')
        const selectedPosta = postArray.find(postDta => postDta.Hash === hash)
        if(selectedPosta){
          return(
            <div className='detail_dewan'>
          <a  href="/tatkel/sekre">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3 className='title_dewan'dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p  className='title_dewan' dangerouslySetInnerHTML={{ 
              __html: `
                ${
                  selectedPost.Title === 'Hartono Tjahjadi Adiwana' ? 
                    (selectedLanguage === 'id' ? 'Komisaris Utama' : 'President Commissioner') :
                    selectedPost.Title === 'Josephine Sukmadewi Karjadi' ? 
                    (selectedLanguage === 'id' ? 'Komisaris' : 'Commissioner') :
                    (selectedLanguage === 'id' ? 'Komisaris Independen' : 'Independent Commissioner')
                }
              `
            }}></p>
            <p dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
          );
        }
      }

     } else if (location.pathname === "/tatkel/sekre") {
      const postArray = Array.isArray(postData) ? postData : [];
      const postArray1 = Array.isArray(postDta) ? postDta : [];
      const postArray2 = Array.isArray(postData1) ? postData1 : [];

      return (
        <>
           <div>
            <h3>{selectedLanguage === 'id' ?  'Sekretaris Perusahaan' : 'Corporate Secretary'}</h3>
            <div className='img_dewan'>
            {postArray.map((post, index) => (
              <div key={index}>
                <a style={{textDecoration : "none", color: "black"}} href={`/tatkel/sekre/cv/${post.Hash}`}>
                <img src={post.Img_path} className='content_sekre'></img>
                <br/>
                <div className='name_dewan'>
                <b><p className='name_sekre'>{post.Title}</p></b>
                {/* <p>{
                  post.Title === 'Vivi Henny' ? 
                    (selectedLanguage === 'id' ? 'Ketua' : 'Head') :
                    (selectedLanguage === 'id' ? 'Anggota' : 'Member')
                }</p> */}
                </div>
                </a>
              </div>
            ))}
            </div>
                  <br/>
                  <br/>

                  <div>
                  {postArray2.map((post, index) => (
                  <div key={index} >
                <div className='content_lpp'>
                <div className='isi_lpp'>
                 <p  dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? post.Content_tx : post.Content_tx_en }}></p>
                </div>
                </div>
                  </div>
                  ))}
                  </div>

                  <br/>
                  
            </div>
        </>
      );  
    } else if(location.pathname.startsWith('/tatkel/sekre/')){
      const hash = location.pathname.replace('/tatkel/sekre/cv/','')
      const postArray = Array.isArray(postData) ? postData : [];
      const selectedPost = postArray.find(postDta => postDta.Hash === hash)
      if(selectedPost){
        return(
          <div className='detail_dewan'>
          <a  href="/tatkel/sekre">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3 className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p  className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
            <p  dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
        );
      }
      else if(hash){
        const hash = location.pathname.replace('/tatkel/sekre/cv/','')
        const selectedPosta = postArray.find(postDta => postDta.Hash === hash)
        if(selectedPosta){
          return(
            <div className='detail_dewan'>
          <a  href="/tatkel/sekre">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3 className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p  className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
            <p  dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
          );
        }
      } 
    } 
    else if (location.pathname === "/tatkel/internal") {
      const postArray = Array.isArray(postData) ? postData : [];
      const postArray1 = Array.isArray(postDta) ? postDta : [];
      const postArray2 = Array.isArray(postData1) ? postData1 : [];


      return (
        <>
           <div>
            <h3>Internal Audit</h3>
            <div className='img_dewan'>
            {postArray.map((post, index) => (
              <div key={index}>
                <a style={{textDecoration : "none", color: "black"}} href={`/tatkel/internal/cv/${post.Hash}`}>
                <img src={post.Img_path} className='content_sekre'></img>
                <br/>
                <div className='name_dewan'>
                <b><p className='name_sekre'>{post.Title}</p></b>
                  {/* <p>{
                    post.Title === 'Vivi Henny' ? 
                      (selectedLanguage === 'id' ? 'Ketua' : 'Head') :
                      (selectedLanguage === 'id' ? 'Anggota' : 'Member')
                  }</p> */}
                </div>
                </a>
              </div>
            ))}
            </div>
            <br/>
                  <br/>

                  <div>
                  {postArray2.map((post, index) => (
                  <div key={index} >
                <div className='content_lpp'>
                <div className='isi_lpp'>
                  <p  dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? post.Content_tx : post.Content_tx_en }}></p>
                </div>
                </div>
                  </div>
                  ))}
                  </div>

                  <br/>
                  
                  <h3 className="tt">Piagam</h3>
                  <br />
                  {postArray1.map((post, index) => (
                    <div key={index}>
                <div className='content_piagam'>
                 <div> 
                   <img src={pdf} className='pdf' onClick={() => handleDownload(post.File_upload)}></img>
                 </div>
                 <div className='isi_piagam'>
                   <p style={{cursor: "pointer"}} onClick={() => handleDownload(post.File_upload)}>{post.Title}</p>
                 </div>
              </div>
            </div>
                  ))}
            </div>
        </>
      );  
    } else if(location.pathname.startsWith('/tatkel/internal/')){
      const hash = location.pathname.replace('/tatkel/internal/cv/','')
      const postArray = Array.isArray(postData) ? postData : [];
      const selectedPost = postArray.find(postDta => postDta.Hash === hash)
      if(selectedPost){
        return(
          <div className='detail_dewan'>
          <a  href="/tatkel/internal">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3 className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p  className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
            <p dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
        );
      }
      else if(hash){
        const hash = location.pathname.replace('/tatkel/internal/cv/','')
        const selectedPosta = postArray.find(postDta => postDta.Hash === hash)
        if(selectedPosta){
          return(
            <div className='detail_dewan'>
          <a  href="/tatkel/sekre">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
          <br/>
          <br/>
          <div className='cv_dewan'>
            <div>
            <img className='detail_dewan' src={selectedPost.Img_path}></img>
            </div>
            <div>
            <h3  className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Title}}></h3>
            <p   className='title_dewan' dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
            <p dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx_en}}></p>
            </div>
          </div>
          </div>
          );
        }
      } 
    }  
    else {
      return (
        <h1>Halaman Kosong</h1>
      );
    }
  }

  return (
    <div>
      <div className='navbar_m'>
        <div className='bantiban'>
          <Navbar isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
          <div className='container hh'>
            <p>
              {selectedLanguage === 'en' ? 'GCCG' : 'Tata Kelola'}
            </p>
          </div>
        </div>
      </div>
      <div className="container cc">
      <div className="ctck">
        <div className="side-menu">       
          <SideMenu />   
        </div>
        <div className="ctck_ktn content">
          <CustomKonten />
        </div>
      </div>
      </div>
      <div className="mn_content">
        <div className='bar_mn'>
        <SideMenu />
          </div>
          <center>
            <hr className='hr_hp'/>
          </center>
          <div className='content_mn'>
            <CustomKonten />
          </div>
      </div>
      <div className='footer_m'>
        <Footer />
      </div>
          </div>
  );
};

export default TenkaPage;
