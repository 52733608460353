import ig from "../../assets/logo/footer/instagram.png";
import tw from "../../assets/logo/footer/twitter.png";
import fb from "../../assets/logo/footer/facebook.png";
import { useLanguage } from "../../context/LanguageContext";

function Footer() {
  const { selectedLanguage } = useLanguage();
  return (
    <footer className="footer-container ">
      <div className="atasf">
        <div className="container">
        <div className="col-md prom">
          <p>{selectedLanguage === 'id'
            ? 'Untuk pengalaman belanja lebih Nyaman, Download aplikasi Eci.id'
            : 'For a more comfortable shopping experience, download the Eci.id application'}
          </p>
        </div>
        <div className="col-sm but">
          <button type="button" className="butdown">
            <a href="https://play.google.com/store/search?q=eci.id+by+electronic+city&c=apps&hl=id-ID">{selectedLanguage === 'id' ?  'Download Sekarang': 'Download Now'}</a>
          </button>
        </div>
        </div>
      </div>

      <div className="bawahf">
        <div className="container" >
          <div className="row">
            <div className="col-md-6 row1">
              <div className="rr">
          <a><p>{selectedLanguage === 'id' ?  'Belanja Online': 'Online Shopping'}</p> </a> &nbsp;&nbsp; <a href="https://eci.id/careers">{selectedLanguage === 'id' ?  'Karir': 'Career'}</a>
          <div className="col-auto iconif">
          <div>
            <a href="https://www.instagram.com/electroniccityid/">
              <img src={ig} alt="instagram" className="iconf ig" />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/ElectronicCityIndonesia/">
              <img src={fb} alt="facebook" className="iconf fc" />
            </a>
          </div>
          <div>
            <a href="https://twitter.com/electronic_city?lang=en">
              <img src={tw} alt="twitter" className="iconf tw" />
            </a>
          </div>
        </div>
        </div>
        </div>
        <div className="col-md-6 row2">        
            <p>&copy;&nbsp;Electronic City</p>
          </div>
          </div>
         </div> 
        </div>
        
    </footer>
  );
}

export default Footer;
