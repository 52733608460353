import ig from "../assets/logo/ig.png";
import tw from "../assets/logo/tw.png";
import x from "../assets/logo/footer/x.png";
import tt from "../assets/logo/footer/tiktok.png";
import fb from "../assets/logo/fb.png";
import yt from "../assets/logo/youtube.png"
import "../entity/Footerr.css"
import { useLanguage } from "../context/LanguageContext";

function Footera() {
  const { selectedLanguage } = useLanguage();
  return (
    <>
  <div className="top">
  <div className="container atas">
  <div class="col-sm-10">{selectedLanguage === 'id'
    ? 'Untuk pengalaman belanja lebih Nyaman, Download aplikasi Eci.id'
    : 'For a more comfortable shopping experience, download the Eci.id application'}
  </div>
  <div class="col-sm-4">
  <button type="button" className="skrng">
            <a href="https://play.google.com/store/search?q=eci.id+by+electronic+city&c=apps&hl=id-ID">{selectedLanguage === 'id' ?  'Download Sekarang': 'Download Now'}</a>
          </button>
  </div>
</div>
</div>

<div className="bottom">
  <div className="container bawah">
  <div class="col-sm-6">
    <div className="outsos">
    <a href="https://eci.id/" style={{textDecoration:"none", color:"white", textAlign: "center"}}>{selectedLanguage === 'id' ?  'Belanja Online': 'Online Shopping'}</a> &nbsp;&nbsp; <a href="https://eci.id/careers"  style={{textDecoration:"none", color:"white"}}></a> &nbsp;&nbsp; <a style={{textDecoration:"none", color:"white", textAlign: "center"}}>{selectedLanguage === 'id' ?  'Ikuti Kami': 'Follow  Us'}</a>
  <div className="sosmed">
          <div>
            <a href="https://www.instagram.com/electroniccityid/">
              <img src={ig} alt="instagram" className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/ElectronicCityIndonesia/">
              <img src={fb} alt="facebook" className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://x.com/electronic_city?lang=en">
              <img src={x} alt="twitter"  className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@ElectronicCityID/videos">
              <img src={yt} alt="youtube"  className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.tiktok.com/@electroniccityid?lang=en">
              <img src={tt} alt="tiktok"  className="iconf" />
            </a>
          </div>
        
  </div>
  </div>
  </div>
  {/* <div className="col-sm-6">        
            <p className="copy">&copy;&nbsp;Electronic City</p>
          </div> */}
</div>
</div>

  </>
  );
}

export default Footera;
